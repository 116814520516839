import React, { useState, useEffect } from 'react';
import { Layout, Menu, Drawer, Button } from 'antd';
import {
  RightCircleOutlined,
  LeftCircleOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { adminRoutes, initAdminRoutes } from '../../configs/menuItems';

const { Sider } = Layout;

// 存儲菜單項的額外數據
const menuItemsData = {};

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // 監聽窗口大小變化
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    // 初始檢查
    checkMobile();
    
    // 添加事件監聽器
    window.addEventListener('resize', checkMobile);
    
    // 清理事件監聽器
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  // 將純數據結構轉換為 Ant Design Menu 格式
  const transformMenuItems = (routes) => {
    return routes.map(route => {
      // 清理頂層菜單項的數據
      const { key, icon, label, children } = route;
      
      return {
        key,
        icon,
        label,
        children: children.map(child => {
          // 清理子菜單項的數據
          const { key: childKey, label: childLabel, path, isGrafana, viewId, ...restChildProps } = child;
          
          // 將額外數據存儲在全局對象中
          menuItemsData[childKey] = {
            path,
            isGrafana,
            viewId,
            ...restChildProps
          };
          
          // 只返回 Ant Design Menu 組件支持的屬性
          return {
            key: childKey,
            label: childLabel
          };
        })
      };
    });
  };

  useEffect(() => {
    // 初始化 adminRoutes
    const loadMenuItems = async () => {
      try {
        // 使用 initAdminRoutes 返回的結果來更新 menuItems
        const updatedRoutes = await initAdminRoutes();
        // 將純數據結構轉換為 Ant Design Menu 格式
        setMenuItems(transformMenuItems(updatedRoutes));
        
        // 如果 updatedRoutes 為空或未定義，使用 adminRoutes 作為備用
        if (!updatedRoutes || updatedRoutes.length === 0) {
          setMenuItems(transformMenuItems([...adminRoutes]));
        }
      } catch (error) {
        console.error('Failed to load menu items:', error);
        setMenuItems(transformMenuItems([...adminRoutes]));
      }
    };
    
    loadMenuItems();
  }, []);

  // 改進的選中狀態處理函數
  const selectedKeysHandler = () => {
    const pathname = location.pathname;
    
    // 如果不是 /admin 路徑，不需要選中任何項目
    if (!pathname.startsWith('/admin')) {
      return [];
    }
    
    // 從路徑中提取表名或頁面名稱
    const pageName = pathname.split('/').pop();
    
    // 在所有菜單項中查找匹配的 key
    let selectedKey = null;
    
    // 遍歷 menuItemsData 查找匹配的路徑
    for (const key in menuItemsData) {
      const item = menuItemsData[key];
      if (item.path && (item.path.endsWith(pageName) || item.path === pathname)) {
        selectedKey = key;
        break;
      }
    }
    
    // 如果找不到匹配的 key，嘗試從原始 adminRoutes 中查找
    if (!selectedKey) {
      for (const group of adminRoutes) {
        if (group.children && group.children.length > 0) {
          for (const item of group.children) {
            if (item.key === pageName || (item.path && (item.path.endsWith(pageName) || item.path === pathname))) {
              selectedKey = item.key;
              break;
            }
          }
        }
        if (selectedKey) break;
      }
    }
    
    return selectedKey ? [selectedKey] : [];
  };

  // 改進的菜單點擊處理函數
  const handleMenuClick = ({ key, keyPath }) => {
    // 首先檢查是否是頂層菜單項
    if (keyPath.length === 1) {
      // 頂層菜單項通常不需要導航
      return;
    }
    
    // 從 menuItemsData 中獲取路徑
    const itemData = menuItemsData[key];
    if (itemData && itemData.path) {
      navigate(itemData.path);
      
      // 如果是手機模式，點擊後關閉抽屜
      if (isMobile) {
        setDrawerVisible(false);
      }
      
      return;
    }
    
    // 如果在 menuItemsData 中找不到，嘗試從原始 adminRoutes 中查找
    let targetPath = null;
    for (const group of adminRoutes) {
      if (group.children && group.children.length > 0) {
        for (const item of group.children) {
          if (item.key === key) {
            targetPath = item.path;
            break;
          }
        }
      }
      if (targetPath) break;
    }
    
    // 如果找到路徑，進行導航
    if (targetPath) {
      navigate(targetPath);
      
      // 如果是手機模式，點擊後關閉抽屜
      if (isMobile) {
        setDrawerVisible(false);
      }
    } else {
      console.error('Path not found for key:', key);
    }
  };

  // 渲染菜單
  const renderMenu = () => (
    <Menu
      mode="inline"
      className="app-aside-menu"
      inlineIndent={16}
      selectedKeys={selectedKeysHandler()}
      items={menuItems.length > 0 ? menuItems : transformMenuItems(adminRoutes)}
      onClick={handleMenuClick}
    />
  );

  // 手機模式下的菜單按鈕
  const mobileMenuButton = (
    <Button 
      type="primary" 
      icon={<MenuOutlined />} 
      onClick={() => setDrawerVisible(true)}
      style={{ 
        position: 'fixed', 
        top: '72px', 
        left: '10px', 
        zIndex: 1000 
      }}
    />
  );

  // 手機模式下的抽屜菜單
  const mobileMenu = (
    <>
      {mobileMenuButton}
      <Drawer
        title="選單"
        placement="left"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        width={'70%'}
        styles={{ body: { padding: 0 } }}
      >
        {renderMenu()}
      </Drawer>
    </>
  );

  // 桌面模式下的側邊欄
  const desktopMenu = (
    <Sider
      collapsible
      className="app-aside"
      collapsed={collapsed}
      collapsedWidth={60}
      onCollapse={(value) => setCollapsed(value)}
      trigger={collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />}
    >
      {renderMenu()}
    </Sider>
  );

  return isMobile ? mobileMenu : desktopMenu;
};

export default Sidebar;
