// src/api/assetApi.js
import axios from '../utils/axiosConfig';

const API_BASE_URL = `${process.env.REACT_APP_APIGATEWAY_URL}${!process.env.REACT_APP_IS_DEV ? '/aidp' : ''}`;

// 獲取資產數據
export const getAsset = async (assetType, conditions) => {
  try {
    let url = `${API_BASE_URL}/aq/asset/${assetType}`;
    let payload = {
      params: {
        conditions: JSON.stringify(conditions)
      }
    }
    
    const response = await axios.get(url, payload);
    return response.data;
  } catch (error) {
    console.error(`Error getting ${assetType} asset:`, error);
    throw error;
  }
};

export const getCombinedAsset = async () => {
  try {
    let url = `${API_BASE_URL}/aq/combined_asset`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(`Error getting combined asset:`, error);
    throw error;
  }
};

// 為每種資產類型創建專門的函數
export const getPlants = async (conditions) => getAsset('plant', conditions);
export const getPlantsInfo = async (conditions) => getAsset('plant_info', conditions);
export const getPonds = async (conditions) => getAsset('pond', conditions);
export const getMainProjects = async (conditions) => getAsset('main_project', conditions);
export const getSubProjects = async (conditions) => getAsset('sub_project', conditions);
export const getTasks = async (conditions) => getAsset('task', conditions);
export const getAssetTypes = async (conditions) => getAsset('type', conditions);
export const getWorkers = async (conditions) => getAsset('worker', conditions);
export const getTaskTypes = async (conditions) => getAsset('task_type', conditions);
export const getMappingTable = async (conditions) => getAsset('mapping_table', conditions);

const assetObj = {
  getAsset,
  getPlantsInfo,
  getPlants,
  getPonds,
  getMainProjects,
  getSubProjects,
  getTasks,
  getAssetTypes,
  getWorkers,
  getCombinedAsset,
  getTaskTypes,
  getMappingTable,
};

export default assetObj;