import { BarChartOutlined, FileTextOutlined } from '@ant-design/icons';
import { getMappingTable } from '../api/asset';

// 新增一個 mappingTable 快取
let mappingTableCache = null;

// 使用純數據結構定義 adminRoutes，不包含 JSX 元素
export const adminRoutes = [
  {
    label: '養殖管理報表',
    key: 'reports',
    icon: <BarChartOutlined />,
    children: [
      { 
        label: '案場儀表板', 
        key: 'site-overview', 
        path: '/admin/site-overview',
        isGrafana: true 
      },
      { 
        label: '表單填寫統計', 
        key: 'site-statistics', 
        path: '/admin/site-statistics',
        isGrafana: true 
      }
    ]
  },
  {
    label: '養殖專案管理',
    key: 'project',
    icon: <FileTextOutlined />,
    children: [] // 將由 getMappingTable 動態填充
  },
  {
    label: '操作紀錄細項',
    key: 'operations',
    icon: <FileTextOutlined />,
    children: [] // 將由 getMappingTable 動態填充
  }
];

// 初始化 adminRoutes 的函數，使用純數據結構
export const initAdminRoutes = async () => {
  try {
    if (!mappingTableCache) {
      const response = await getMappingTable();
      mappingTableCache = response.data;
    }

    if (mappingTableCache && mappingTableCache.length > 0) {
      // 找到養殖專案管理的項目
      const projectItems = mappingTableCache
        .filter(item => item['類別'] === '養殖專案管理')
        .sort((a, b) => a.manualSort - b.manualSort); // 根據 manualSort 排序
      
      // 找到操作記錄的項目
      const operationItems = mappingTableCache
        .filter(item => item['類別'] === '操作紀錄細項')
        .sort((a, b) => a.manualSort - b.manualSort); // 根據 manualSort 排序

      // 更新養殖專案管理的子項目，使用純數據結構
      const projectRoute = adminRoutes.find(route => route.key === 'project');
      if (projectRoute) {
        projectRoute.children = projectItems.map(item => ({
          label: item.table_name,
          key: item.table_name,
          path: `/admin/${item.table_name}`,
          viewId: item.primaryViewId
        }));
      }

      // 更新操作記錄細項的子項目，使用純數據結構
      const operationsRoute = adminRoutes.find(route => route.key === 'operations');
      if (operationsRoute) {
        operationsRoute.children = operationItems.map(item => ({
          label: item.table_name,
          key: item.table_name,
          path: `/admin/${item.table_name}`,
          viewId: item.primaryViewId
        }));
      }
    }
    
    // 返回更新後的 adminRoutes
    return [...adminRoutes];
  } catch (error) {
    console.error('Failed to initialize admin routes:', error);
    return adminRoutes;
  }
};

export const getAllAdminPaths = () => {
  const paths = ['/admin'];
  adminRoutes.forEach(group => {
    if (group.children) {
      group.children.forEach(item => {
        if (item.path) {
          paths.push(item.path);
        }
      });
    }
  });
  return paths;
};

export const clearTableInfoCache = () => {
  mappingTableCache = null;
}; 